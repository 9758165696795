import * as React from "react";
import { graphql } from "gatsby";
import PageComponent from "src/components/pages/blogpost";

const BlogPage = ({ data, location }) => {
  const copy = {
    title: data.file.childMdx.frontmatter.title,
    date: data.file.childMdx.frontmatter.date,
    body: data.file.childMdx.body,
  };

  return (
    <PageComponent
      lang="he"
      location={location}
      copy={copy}
      pageTitle={copy.title}
    />
  );
};

export const query = graphql`
  query POST_BY_SLUG_HE($slug: String) {
    file(
      childMdx: { slug: { eq: $slug } }
      sourceInstanceName: { eq: "he/blog" }
    ) {
      childMdx {
        id
        slug
        body
        frontmatter {
          date(formatString: "DD.MM.YYYY")
          title
        }
      }
    }
  }
`;

export default BlogPage;
