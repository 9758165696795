import * as React from "react";
import Layout from "src/components/layout";
import { MDXRenderer } from "gatsby-plugin-mdx";

const PageComponent = ({ location, copy, pageTitle, lang }) => {
  return (
    <Layout pageTitle={pageTitle} location={location} lang={lang}>
      <section className="inner-content mx-auto my-20">
        <article className="mx-4">
          <h2>{copy.title}</h2>
          <p className="pt-4 pb-8 ">{copy.date} </p>
          <div className="blog-content">
            <MDXRenderer>{copy.body}</MDXRenderer>
          </div>
        </article>
      </section>
    </Layout>
  );
};

export default PageComponent;
